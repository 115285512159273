import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const blogs = [
  {
    id:1,
    title: 'Torakal Cərrahiyyə: Nədir və Hansı Xəstəlikləri Əhatə Edir?',
    image: 'assets/img/services/service-img1.jpg', // Use actual image paths
    icon: 'fas fa-user-md',
  },
  {
    id:2,
    title: 'Ağciyər Xərçəngi: Simptomları Tanıma və Erkən Diaqnozun Əhəmiyyəti',
    image: 'assets/img/services/service-img2.jpg',
    icon: 'fas fa-flask',
  },
  {
    id:3,
    title: 'Erkən Diaqnozun Rol',
    image: 'assets/img/services/service-img3.jpg',
    icon: 'fas fa-syringe',
  },
  {
    id:4,
    title: 'Ağciyər Emfizemi: Nədir və Necə Qarşısını Almaq Olar?',
    image: 'assets/img/services/service-img4.jpg',
    icon: 'fas fa-stethoscope',
  },
  {
    id:5,
    title: 'Ağciyər Pnevmoniyası: Səbəblər, Müalicə və Profilaktika',
    image: 'assets/img/services/service-img5.jpg',
    icon: 'fas fa-pills',
  },
  {
    id:6,
    title: 'Torakal Cərrahiyyədə Minimally İnvaziv Yanaşmalar: VATS və Robotik Cərrahiyyə',
    image: 'assets/img/services/service-img6.jpg',
    icon: 'fas fa-ambulance',
  },
];


const Blogs = () => {
  return (
    <section className="service-area pt-100 pb-70">
      <div className="container">
        <div className="section-title text-center">
          <h2>Bloqlar</h2>
          <p>
            Torokal və döş qəfəsi ilə bağlı faydaı məlumatlar
          </p>
        </div>
        <div className="row pt-45">
          {blogs.map((service, index,id) => (
            <div class="col-lg-4 col-md-6">
            <div class="blog-item">
            <div class="blog-item-img">
            <a href={`/blog/${service.id}`}>
            <img src={service.image} alt="Images"></img>
            </a>
            <div class="date">
            <ul>
            <li>16</li>
            <li>Mar</li>
            </ul>
            </div>
            </div>
            <div class="content">
            <span class="topic">
            <a href={`/blog/${service.id}`}>Torakal</a>
            </span>
            <h3>
            <a href={`/blog/${service.id}`}>{service.title}</a>
            </h3>
            </div>
            </div>
            </div>
          ))}
        </div>
      </div>
      <div className="service-dots">
        <img src="assets/img/services/service-dots.png" alt="Dots" />
      </div>
    </section>
  );
};

export default Blogs;
