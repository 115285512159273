import React from 'react'
import Services from '../../Components/home/Services/Services'
import Appointment from '../../Components/home/Appointment/Appointment'
import Blogs from '../../Components/home/Blogs/Blogs'
import BlogDetailed from '../../Components/home/BlogDetail/BlogDetail'

const BlogDetail = () => {
  return (
    <>
      <div className="inner-banner inner-bg7">
        <div className="container">
          <div className="inner-title">
            <h3>Bloq haqqında</h3>
            <ul>
              <li>
                <a href="/">Əsas</a>
              </li>
              <li>Bloq haqqında</li>
            </ul>
          </div>
        </div>
        <div className="inner-banner-shape">
          <div className="shape1">
            <img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
          </div>
          <div className="shape2">
            <img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
          </div>
        </div>
      </div>
      <BlogDetailed/>
    </>
  )
}

export default BlogDetail;
