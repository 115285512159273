import React, { useState } from 'react';
import { FaSearch, FaUser, FaQuestionCircle, FaShoppingCart, FaTh, FaChevronRight } from 'react-icons/fa';


const AboveHeader = () => {


  return (
    <>
   <header class="top-header top-header-bg">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-8 col-md-8">
<div class="header-left">
<div class="header-left-card">
<ul class="">
<li>
<div class="head-icon">
<i class="bx bx-mail-send"></i>
</div>
<a href="mailto:info@agciyercerrahi.az">info@agciyercerrahi.az</a>
</li>
<li>
<div class="head-icon">
<i class="bx bx-time-five"></i>
</div>
<a href="#">Həftəiçi: 09.00 - 18.00</a>
</li>
</ul>
</div>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="header-right">
<div class="top-social-link">
<ul>
<li>
<a href="https://www.facebook.com/drelmarmammadov/?locale=az_AZ" target="_blank">
<i class="bx bxl-facebook"></i>
</a>
</li>
<li>
<a href="https://youtu.be/gVFKzcv7_0E?si=dht7F_YjQqLl96qc" target="_blank">
<i class="bx bxl-youtube"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/uzm.dr.mammadov_elmar/" target="_blank">
<i class="bx bxl-instagram"></i>
</a>
</li>
<li>
<a href="https://wikimed.az/portret/elmar-m%C9%99mm%C9%99dov-torakal-c%C9%99rrah-dos-q%C9%99f%C9%99si-c%C9%99rrahi/" target="_blank">
<i class="bx bxl-google-plus"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</header>
    </>
  );
};

export default AboveHeader;

