import React, { useState } from 'react';
import { FaSearch, FaUser, FaQuestionCircle, FaShoppingCart, FaTh, FaChevronRight } from 'react-icons/fa';
import './MeanMenu.css'

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
       <div class="navbar-area">
       <div className="mobile-nav mean-container">
      <div className="mean-bar">
        <a href="#nav" className="meanmenu-reveal" onClick={toggleMenu} style={{ right: '0', left: 'auto' }}>
          <span><span><span></span></span></span>
        </a>
        <nav className={`mean-nav ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}>
          <ul className="navbar-nav m-auto">
            <li className="nav-item">
              <a href="/" className="nav-link">Əsas <i className="bx bx-chevron-down"></i></a>
            </li>
            <li className="nav-item">
              <a href="/about" className="nav-link">Haqqımızda</a>
            </li>
            <li className="nav-item">
              <a href="/services" className="nav-link">Xidmətlər</a>
            </li>
            <li className="nav-item">
              <a href="/appointment" className="nav-link">Rezervasiya</a>
            </li>
            <li className="nav-item">
              <a href="/faq" className="nav-link">FAQ</a>
            </li>
            <li className="nav-item">
              <a href="/contact" className="nav-link">Əlaqə</a>
            </li>

            {/* Add other nav items here */}
          </ul>
        </nav>
      </div>
      <a href="/" className="logo">
        <img src="/assets/img/logo.png" className="logo-one" alt="Logo" />
        <img src="/assets/img/logo-2.png" className="logo-two" alt="Logo" />
      </a>
    </div>


<div class="main-nav">
<div class="container">
<nav class="navbar navbar-expand-md navbar-light ">
<a class="navbar-brand" href="/">
<img src="/assets/img/logo.png" class="logo-one" alt="Logo"></img>
<img src="/assets/img/logo-2.png" class="logo-two" alt="Logo"></img>
</a>
<div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" style={{display:'block'}}>
<ul class="navbar-nav m-auto">
<li class="nav-item">
<a href="/" class="nav-link">
ƏSAS
</a>
</li>
<li class="nav-item">
<a href="#" class="nav-link">
HAQQIMIZDA
<i class="bx bx-chevron-down"></i>
</a>
<ul class="dropdown-menu">
<li class="nav-item">
<a href="/about/1" class="nav-link">
Doktor Elmar Məmmədov
</a>
</li>
<li class="nav-item">
<a href="/about/2" class="nav-link">
Doktor Ramil Kərimov 
</a>
</li>
</ul>
</li>
<li class="nav-item">
<a href="/services" class="nav-link">
XİDMƏTLƏR
</a>
</li>
<li class="nav-item">
<a href="/appointment" class="nav-link">
REZERVASİYA
</a>
</li>
<li class="nav-item">
<a href="/faq" class="nav-link">
FAQ
</a>
</li>
<li class="nav-item">
<a href="/blog" class="nav-link">
BLOQ
</a>
</li>
<li class="nav-item">
<a href="/contact" class="nav-link">
ƏLAQƏ
</a>
</li>
</ul>
<div class="others-options d-flex align-items-center">
<a href='/appointment'>
<button type="submit" class="default-btn">
    Görüş təyin et
    </button>
</a>
</div>
</div> 
</nav>
</div>
</div>
<div class="side-nav-responsive">
<div class="container">
{/* <div class="dot-menu">
<div class="circle-inner">
<div class="circle circle-one"></div>
<div class="circle circle-two"></div>
<div class="circle circle-three"></div>
</div>
</div> */}
<div class="container">
<div class="side-nav-inner">
<div class="side-nav justify-content-center align-items-center">
<div class="side-item">
<div class="option-item">
<i class="search-btn bx bx-search"></i>
<i class="close-btn bx bx-x"></i>
<div class="search-overlay search-popup">
<div class="search-box">
<form class="search-form">
<input class="search-input" name="search" placeholder="Search" type="text"></input>
<button class="search-button" type="submit">
<i class="bx bx-search"></i>
</button>
</form>
</div>
</div>
</div>
<div class="option-item">
<div class="add-cart-btn">
<a href="#" class="cart-btn-icon">
<i class="bx bx-shopping-bag"></i>
<span>0</span>
</a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
    </>
  );
};

export default Header;








