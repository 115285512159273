import React from 'react'
import Services from '../../Components/home/Services/Services'
import Appointment from '../../Components/home/Appointment/Appointment'

const ServicesUs = () => {
  return (
    <>
      <div className="inner-banner inner-bg7">
        <div className="container">
          <div className="inner-title">
            <h3>Xidmətlər</h3>
            <ul>
              <li>
                <a href="/">Əsas</a>
              </li>
              <li>Xidmətlər</li>
            </ul>
          </div>
        </div>
        <div className="inner-banner-shape">
          <div className="shape1">
            <img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
          </div>
          <div className="shape2">
            <img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
          </div>
        </div>
      </div>
      <Services />
      <Appointment />
      <div className="banner-bottom-three pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="banner-bottom-item">
                <i className="flaticon-first-aid-kit"></i>
                <h3>Konsultasiya</h3>
                <p>Həkimə müraciət etməklə sağlamlığınızı qoruyun.</p>
                <div className="circle"></div>
                <div className="line"></div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="banner-bottom-item">
                <i className="flaticon-fast-delivery"></i>
                <h3>Təcili müdaxilə</h3>
                <p>Təcili vəziyyətlərdə sürətli və effektiv yardım alırsınız.</p>
                <div className="circle"></div>
                <div className="line"></div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
              <div className="banner-bottom-item">
                <i className="flaticon-laptop"></i>
                <h3>Peşəkar mütəxəssis</h3>
                <p>İşində peşəkar və təcrübəli həkimlərimiz sizin yanınızdadır.</p>
                <div className="circle"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesUs;
