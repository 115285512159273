import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Header from "./Components/home/Header/Header";
import AboveHeader from "./Components/home/AboveHeader/AboveHeader";
import Footer from "./Components/home/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import { ToastContainer } from "react-toastify";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Faq from "./Pages/Faq/Faq";
import Appointment from "./Pages/Appointment/Appointment";
import NotFound from "./Pages/NotFound/NotFound";
import Services from "./Pages/Services/Services";
import AboutFirst from "./Pages/AboutFirst/AboutFirst";
import AboutSecond from "./Pages/AboutSecond/AboutSecond";
import Blog from "./Pages/Blog/Blog";
import BlogDetail from "./Pages/BlogDetail/BlogDetail";



const Layout = () => {
  return (
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <AboveHeader/>
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route path="/" element={ <Home />}></Route>
        <Route path="/about" element={ <About />}></Route>
        <Route path="/about/1" element={ <AboutFirst />}></Route>
        <Route path="/about/2" element={ <AboutSecond />}></Route>
        <Route path="/contact" element={ <Contact />}></Route>
        <Route path="/appointment" element={ <Appointment />}></Route>
        <Route path="/faq" element={ <Faq />}></Route>
        <Route path="/blog" element={ <Blog />}></Route>
        <Route path="/blog/:id" element={ <BlogDetail />}></Route>
        <Route path="/services" element={ <Services />}></Route>
        <Route path="*" element={ <NotFound />}></Route>
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;




