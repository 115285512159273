import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const services = [
  {
    title: 'Tibb Konsultasiyası',
    image: 'assets/img/services/service-img1.jpg', // Use actual image paths
    icon: 'fas fa-user-md',
  },
  {
    title: 'Laboratoriya Testi',
    image: 'assets/img/services/service-img2.jpg',
    icon: 'fas fa-flask',
  },
  {
    title: 'Cərrahi Əməliyyat',
    image: 'assets/img/services/service-img3.jpg',
    icon: 'fas fa-syringe',
  },
  {
    title: 'Tibbi Müalicə',
    image: 'assets/img/services/service-img4.jpg',
    icon: 'fas fa-stethoscope',
  },
  {
    title: 'Apteka Xidməti',
    image: 'assets/img/services/service-img5.jpg',
    icon: 'fas fa-pills',
  },
  {
    title: 'Təcili Xidmət',
    image: 'assets/img/services/service-img6.jpg',
    icon: 'fas fa-ambulance',
  },
];


const Services = () => {
  return (
    <section className="service-area pt-100 pb-70">
      <div className="container">
        <div className="section-title text-center">
          <h2>Təmin etdiyimiz xidmətlər</h2>
          <p>
            Torokal və döş qəfəsi ilə bağlı bütün problemlərinizi həll edin
          </p>
        </div>
        <div className="row pt-45">
          {services.map((service, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="service-card">
                <a href="service-details.html">
                  <img src={service.image} alt={service.title} />
                </a>
                <div className="service-content">
                  <div className="service-icon">
                    <i className={service.icon}></i> {/* Use Font Awesome icon */}
                  </div>
                  <h3>
                    <a href="service-details.html">{service.title}</a>
                  </h3>
                  <div className="content">
                    <p>{service.description}</p>
                  </div>
                </div>
                <div className="service-shape-1">
                  <img src="assets/img/services/service-shape1.png" alt="Shape" />
                </div>
                <div className="service-shape-2">
                  <img src="assets/img/services/service-shape2.png" alt="Shape" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="service-dots">
        <img src="assets/img/services/service-dots.png" alt="Dots" />
      </div>
    </section>
  );
};

export default Services;
