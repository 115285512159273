import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
    <footer class="footer-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-3 col-md-6">
<div class="footer-widget">
<h3>Əlaqə məlumatları</h3>
<p>Tənəffüs həyatdır</p>
<ul class="footer-contact-list">
<li>
<i class="flaticon-pin"></i>
<div class="content">
Bakı şəhəri, Tbilisi Prospekti
</div>
</li>
<li>
<i class="flaticon-phone-call"></i>
<div class="content">
<a href="tel:+994502668800">+994 50 266 88 00</a>
</div>
</li>
<li>
<i class="flaticon-phone-call"></i>
<div class="content">
<a href="tel:+994552322662">+994 55 232 26 62</a>
</div>
</li>
<li>
<i class="bx bxs-envelope"></i>
<div class="content">
<a href="mailto:info@agciyercerrahi.az">info@agciyercerrahi.az</a>
</div>
</li>
</ul>
</div>
</div>
<div class="col-lg-2 col-md-6">
<div class="footer-widget">
<h3>Sürətli keçidlər</h3>
<ul class="footer-list">
<li>
<a href="/about" target="_blank">
<i class="bx bxs-chevron-right"></i>
Haqqımızda
</a>
</li>
<li>
<a href="/faq" target="_blank">
<i class="bx bxs-chevron-right"></i>
FAQ
</a>
</li>
<li>
<a href="/services" target="_blank">
<i class="bx bxs-chevron-right"></i>
Xidmətlər
</a>
</li>
<li>
<a href="/appointment" target="_blank">
<i class="bx bxs-chevron-right"></i>
Rezervasiya
</a>
</li>
<li>
<a href="contact.html" target="_blank">
<i class="bx bxs-chevron-right"></i>
Bizimlə Əlaqə
</a>
</li>
</ul>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="footer-widget">
<h3>İş saatları</h3>
<ul class="open-hours-list">
<li>
Bazar ertəsi
<span>09.00 - 18.00</span>
</li>
<li>
Çərşənbə axşamı
<span>09.00 - 18.00</span>
</li>
<li>
Çərşənbə
<span>09.00 - 18.00</span>
</li>
<li>
Cümə axşamı
<span>09.00 - 18.00</span>
</li>
<li>
Cümə
<span>09.00 - 18.00</span>
</li>
<li>
Şənbə
<span>09.00 - 18.00</span>
</li>
</ul>
</div>
</div>
<div class="col-lg-3 col-md-6">
<div class="footer-widget ps-2">
<div class="footer-logo">
<a href="/">
<img src="/assets/img/logo.png" class="footer-logo1" alt="Images"></img>
<img src="/assets/img/logo-2.png" class="footer-logo2" alt="Images"></img>
</a>
</div>
<p>Tənəffüs həyatdır</p>
<ul class="social-link">
<li>
<a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a>
</li>
<li>
<a href="https://www.twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a>
</li>
<li>
<a href="https://www.pinterest.com/" target="_blank"><i class="bx bxl-pinterest-alt"></i></a>
</li>
<li>
<a href="https://www.youtube.com/" target="_blank"><i class="bx bxl-youtube"></i></a>
</li>
</ul>
</div>
</div>
</div>
</div>
</footer>
    </>
  );
}
