import React from 'react'
import Emergency from '../../Components/home/Emergency/Emergency'
import Appointment from '../../Components/home/Appointment/Appointment'
import Newsletter from '../../Components/home/Newsletter/Newsletter'

const AppointmentUs = () => {
  return (
    <>
    <div class="inner-banner inner-bg2">
<div class="container">
<div class="inner-title">
<h3>Rezervasiya</h3>
<ul>
<li>
<a href="/">Əsas</a>
</li>
<li>Rezervasiya</li>
</ul>
</div>
</div>
<div class="inner-banner-shape">
<div class="shape1">
<img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
</div>
</div>
</div>
<div class="appointment-area pt-100 pb-70">
    <div class="container">
    <div class="row">
    <div class="col-lg-6 col-xxl-5">
    <div class="appointment-img">
    <img src="assets/img/appointment/appointment-img.png" alt="Images"></img>
    </div>
    </div>
    <div class="col-lg-6 col-xxl-7">
    <div class="appointment-from">
    <h2>Görüş təyin et</h2>
    <p>Formu doldurun, rezervasiya təyin edin</p>
    <form>
    <div class="row">
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <select class="form-control" style={{display:'none'}}>
    <option value="">Select Departments</option>
    <option value="">Dental Care</option>
    <option value="">Cardiology</option>
    <option value="">Neurology</option>
    <option value="">Orthopedics</option>
    <option value="">Medicine</option>
    </select><div class="nice-select form-control" tabindex="0"><span class="current">Şöbəni seçin</span><ul class="list"><li data-value="" class="option selected">Select Departments</li><li data-value="" class="option">Dental Care</li><li data-value="" class="option">Cardiology</li><li data-value="" class="option">Neurology</li><li data-value="" class="option">Orthopedics</li><li data-value="" class="option">Medicine</li></ul></div>
    </div>
    </div>
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <select class="form-control" style={{display:'none'}}>
    <option value="">Select Doctor</option>
    <option value="">Dr. James Adult</option>
    <option value="">Dr. James Alison</option>
    <option value="">Dr. Peter Adlock</option>
    <option value="">Dr. Jelin Alis</option>
    <option value="">Dr. Josh Taylor</option>
    <option value="">Dr. Steven Smith</option>
    </select><div class="nice-select form-control" tabindex="0"><span class="current">Həkimi seçin</span><ul class="list"><li data-value="" class="option selected">Select Doctor</li><li data-value="" class="option">Dr. James Adult</li><li data-value="" class="option">Dr. James Alison</li><li data-value="" class="option">Dr. Peter Adlock</li><li data-value="" class="option">Dr. Jelin Alis</li><li data-value="" class="option">Dr. Josh Taylor</li><li data-value="" class="option">Dr. Steven Smith</li></ul></div>
    </div>
    </div>
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <select class="form-control" style={{display:'none'}}>
    <option value="">Select Specialist</option>
    <option value="">Cardiologists</option>
    <option value="">Dermatologists</option>
    <option value="">Endocrinologists</option>
    <option value="">Gastroenterologists</option>
    <option value="">Allergists</option>
    <option value="">Immunologists</option>
    </select><div class="nice-select form-control" tabindex="0"><span class="current">Mütəxəssis seçin</span><ul class="list"><li data-value="" class="option selected">Select Specialist</li><li data-value="" class="option">Cardiologists</li><li data-value="" class="option">Dermatologists</li><li data-value="" class="option">Endocrinologists</li><li data-value="" class="option">Gastroenterologists</li><li data-value="" class="option">Allergists</li><li data-value="" class="option">Immunologists</li></ul></div>
    </div>
    </div>
    <div class="col-lg-6 col-sm-6">
    <div class="form-group">
    <input type="text" name="name" class="form-control" required="" data-error="Please enter your name" placeholder="Ad Soyad"></input>
    </div>
    </div>
    <div class="col-lg-6 col-sm-6">
    <div class="form-group">
    <input type="email" name="email" class="form-control" required="" data-error="Please enter your email" placeholder="Email"></input>
    </div>
    </div>
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <div class="input-group date" id="datetimepicker">
    <input type="text" class="form-control" placeholder="Vaxtı seçin"></input>
    <span class="input-group-addon"></span>
    </div>
    </div>
    </div>
    <div class="col-lg-12 col-md-12">
    <button type="submit" class="default-btn">
    Göndər
    </button>
    </div>
    </div>
    </form>
    </div>
    </div>
    </div>
    </div>
    </div>
<Newsletter/>
    </>
  )
}

export default AppointmentUs