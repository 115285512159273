import React from 'react'

const Emergency = () => {
  return (
    <div class="emergency-area ptb-100">
    <div class="container">
    <div class="row">
    <div class="col-lg-7">
    <div class="emergency-content">
    <h2> 24/7 <b>Bizimlə</b> Əlaqə</h2>
    <p>Biz həmişə sizinləyik</p>
    <div class="emergency-icon-content">
    <i class="flaticon-24-hours-1"></i>
    <h3><a href="tel:+994502668800">+994 50 266 88 00</a></h3>
    <h3><a href="tel:+994552322662">+994 55 232 26 62</a></h3>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="emergency-shape">
    <img src="/assets/img/emergency/emergency-shape.png" alt="Images"></img>
    </div>
    </div>
  )
}

export default Emergency