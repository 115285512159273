import React from 'react'
import AboutUs from '../../Components/home/About/About';
import Emergency from '../../Components/home/Emergency/Emergency';
import Serve from '../../Components/Serve/Serve';

const AboutSecond = () => {
  return (
   <>
    <div class="inner-banner inner-bg1">
<div class="container">
<div class="inner-title">
<h3>Uzman Dr.Ramil Kərimov haqqında</h3>
<ul>
<li>
<a href="/">Əsas</a>
</li>
<li>Haqqımızda</li>
</ul>
</div>
</div>
<div class="inner-banner-shape">
<div class="shape1">
<img src="/assets/img/inner-banner/inner-banner-shape1.png" alt="Images"></img>
</div>
<div class="shape2">
<img src="/assets/img/inner-banner/inner-banner-shape2.png" alt="Images"></img>
</div>
</div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-lg-6">
    <div class="about-img">
    <img src="/assets/img/about-img/about-img2.jpg" alt="Images"></img>
    </div>
    </div>
    <div class="col-lg-12">
    <div class="about-content">
    <div class="section-title">
    <span>Haqqımızda</span>
    <h2>Uzman Dr.Ramil Kərimov</h2>
    <p>Uzm. Dr.Ramil Kərimov – Döş qəfəsi cərrahı</p>
    <p>
    2022-ci ildən etibarən Yeni Klinika və LivBonaDea Hospitalda Torakal cərrah vəzifəsində çalışmaqdadır.
    </p>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-one"></i>
    <div class="content">
    <span>Təhsili</span>
    <ul>
      <li>2002-2008-cı illərdə Ramil Kərimov Azərbaycan Dövlət Tibb Universitetində 'Müalicə işi' ixtisası üzrə təhsil alıb.</li>
      <li>2010-2011-cı illərdə ümumi cərrahiyyə ixtisası üzrə internatura keçmişdir</li>
      <li>2015-2017-cı illərdə Gülhane Askeri Tibb Akademisində(GATA) - Torakal cərrahiyyə üzrə ixtisaslaşma keçmişdir.</li>
    </ul>
    </div>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-two"></i>
    <div class="content">
    <span>Üzvlüklər</span>
    <ul>
      <li>Türk Toraks Dərnəyi və TÜSAD üzvüdür
      </li>
    </ul>
    </div>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-one"></i>
    <div class="content">
    <span>İş təcrübəsi</span>
    <ul>
      <li>2008-2010-cu illərdə DOKTOR Ramil Kərimov Füzuli N saylı hərbi hissənin Tibb məntəqə rəisi kimi çalışıb.</li>
      <li>2010-2011-ci illərdə Baş klinik hospitalda ümumi cərrah kimi çalışıb.</li>
      <li>2011-2015-ci illərdə Fizuli hərbi hospitalında ümumi cərrah kimi çalışıb</li>
      <li>2011-2015-ci illərdə Əfqanıstan respublikası Sülh məramlı qüvvələrdə ümumi cərrah kimi çalışıb</li>
      <li>2015-2017-ci illərdə Gülhane Askeri Tibb Akademisində torakal cərrah kimi çalışıb</li>
      <li>2017-2018-ci illərdə Baş klinik hospitalda torakal cərrah kimi çalışıb</li>
      <li>2018-2020-ci illərdə BonaDea Hospitalda torakal cərrah kimi çalışıb</li>
      <li>2020-2022-ci illərdə Bakı Medikal Plazada torakal cərrah kimi çalışıb</li>
      <li>Hal hazırda Yeni klinika və LivBonaDea Hospitalda torakal cərrah kimi fəaliyyətini davam etdirir</li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
<Emergency/>
</>
  )
}

export default AboutSecond