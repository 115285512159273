import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
import { useParams } from 'react-router-dom';

const blogs = [
  {
    id:1,
    title: 'Torakal Cərrahiyyə: Nədir və Hansı Xəstəlikləri Əhatə Edir?',
    image: 'assets/img/services/service-img1.jpg', // Use actual image paths
    icon: 'fas fa-user-md',
  },
  {
    id:2,
    title: 'Ağciyər Xərçəngi: Simptomları Tanıma və Erkən Diaqnozun Əhəmiyyəti',
    image: 'assets/img/services/service-img2.jpg',
    icon: 'fas fa-flask',
  },
  {
    id:3,
    title: 'Erkən Diaqnozun Rol',
    image: 'assets/img/services/service-img3.jpg',
    icon: 'fas fa-syringe',
  },
  {
    id:4,
    title: 'Ağciyər Emfizemi: Nədir və Necə Qarşısını Almaq Olar?',
    image: 'assets/img/services/service-img4.jpg',
    icon: 'fas fa-stethoscope',
  },
  {
    id:5,
    title: 'Ağciyər Pnevmoniyası: Səbəblər, Müalicə və Profilaktika',
    image: 'assets/img/services/service-img5.jpg',
    icon: 'fas fa-pills',
  },
  {
    id:6,
    title: 'Torakal Cərrahiyyədə Minimally İnvaziv Yanaşmalar: VATS və Robotik Cərrahiyyə',
    image: 'assets/img/services/service-img6.jpg',
    icon: 'fas fa-ambulance',
  },
];


const BlogDetail = () => {

  const { id } = useParams(); // URL'den id'yi alıyoruz
  const blogId = parseInt(id); // id'yi tam sayıya çeviriyoruz
  const blog = blogs.find(b => b.id === blogId); // id'ye uygun blogu buluyoruz

  if (!blog) {
    return <p>Blog tapılmadı</p>; // Eğer id'ye uygun blog bulunmazsa
  }



  return (
<>
<div class="blog-details-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-10">
<div class="blog-article">
<div class="article-content">
<h2>{blog.title}</h2>
<p>
Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accu sam et justo duo dolores et ea rebum.
Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
</p>
</div>
<div class="another-content">
<div class="content-img">
<div class="row"> 
<div class="col-6">
<img src="/assets/img/blog/blog-img.jpg" alt="Images"></img>
</div>
<div class="col-6">
<img src="/assets/img/blog/blog-img2.jpg" alt="Images"></img>
</div>
</div>
</div>
<p>
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
</p>
<p>
consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
Excabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
consectetur, adipisci velit
</p>
<p>
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
</p>
</div>
</div>
</div>
</div>
</div>
</div></>
  );
};

export default BlogDetail


