import React from 'react'

const Consulatation = () => {
  return (
    <div class="consultancy-area">
    <div class="container">
    <div class="row">
    <div class="col-lg-8">
    <div class="consultancy-img">
    <img src="assets/img/consultancy/consultancy-img.png" alt="Images"></img>
    </div>
    </div>
    <div class="col-lg-4">
    <div class="consultancy-content">
    <h2>Onlayn konsultasiyaya ehtiyacınız var?</h2>
    <p>Formu doldurun və onlayn konsultasiyaya qoşulun</p>
    <div class="consultancy-form">
    <form>
    <div class="row">
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <input type="text" name="name" id="name" class="form-control" required="" data-error="Please enter your name" placeholder="Ad Soyad"></input>
    </div>
    </div>
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <input type="email" name="email" id="email" class="form-control" required="" data-error="Please enter your email" placeholder="Email"></input>
    </div>
    </div>
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <select class="form-control" style={{display:'none'}}>
    <option value="">Choose Departments</option>
    <option value="">Dental Care</option>
    <option value="">Cardiology</option>
    <option value="">Neurology</option>
    <option value="">Orthopedics</option>
    <option value="">Medicine</option>
    </select><div class="nice-select form-control" tabindex="0"><span class="current">Şöbə seçin</span><ul class="list"><li data-value="" class="option selected">Choose Departments</li><li data-value="" class="option">Dental Care</li><li data-value="" class="option">Cardiology</li><li data-value="" class="option">Neurology</li><li data-value="" class="option">Orthopedics</li><li data-value="" class="option">Medicine</li></ul></div>
    </div>
    </div>
    <div class="col-lg-12 col-sm-12">
    <div class="form-group">
    <select class="form-control" style={{display:'none'}}>
    <option value="">Həkimi seçin</option>
    <option value="">Dr. James Adult</option>
    <option value="">Dr. James Alison</option>
    <option value="">Dr. Peter Adlock</option>
    <option value="">Dr. Jelin Alis</option>
    <option value="">Dr. Josh Taylor</option>
    <option value="">Dr. Steven Smith</option>
    </select><div class="nice-select form-control" tabindex="0"><span class="current">Həkimi seçin</span><ul class="list"><li data-value="" class="option selected">Choose Doctor</li><li data-value="" class="option">Dr. James Adult</li><li data-value="" class="option">Dr. James Alison</li><li data-value="" class="option">Dr. Peter Adlock</li><li data-value="" class="option">Dr. Jelin Alis</li><li data-value="" class="option">Dr. Josh Taylor</li><li data-value="" class="option">Dr. Steven Smith</li></ul></div>
    </div>
    </div>
    <div class="col-lg-12 col-md-12">
    <button type="submit" class="default-btn">
    Göndər
    </button>
    </div>
    </div>
    </form>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Consulatation