import React from 'react'

const About = () => {
  return (
    <div class="about-area pt-100 pb-70">
    <div class="container">
    <div style={{justifyContent:'space-between'}} class="row align-items-center">
    <div class="col-lg-5">
    <div class="about-content">
    <div class="section-title">
    <span>Haqqımızda</span>
    <h2>Uzman Dr.Elmar Məmmədov</h2>
    <p>Uzm. Dr. Elmar Məmmədov – Döş qəfəsi cərrahı</p>
    <p>
    2011-ci ilin sonu etibarı ilə Baku Medical Plaza xəstəxanasında Torakal cərrah vəzifəsində çalışmaqdadır.
    </p>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-one"></i>
    <div class="content">
    <span>Təhsili</span>
    <ul>
      <li>1998-2004-cı illərdə Elmar Məmmədov Azərbaycan Dövlət Tibb Universitetində təhsil alıb.</li>
      <li>2005-2011-cı illərdə Türkiyə, Ege Universiteti Döş Qəfəsi Cərrahiyyəsi bölümündə uzmanlıq keçmişdir.</li>
    </ul>
    </div>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-two"></i>
    <div class="content">
    <span>Üzvlüklər</span>
    <ul>
      <li>2008-ci ildən etibarən ELMAR MƏMMƏDOV TÜSAD (Türk solunum araştırma derneğini) həqiqi üzvü.
      </li>
    </ul>
    </div>
    </div>
    <div class="about-btn">
    <a href="/about/1" class="default-btn">Daha çox</a>
    </div>
    </div>
    </div>
    <div class="col-lg-5">
    <div class="about-content">
    <div class="section-title">
    <span>Haqqımızda</span>
    <h2>Uzman Dr.Ramil Kərimov</h2>
    <p>Uzm. Dr.Ramil Kərimov – Döş qəfəsi cərrahı</p>
    <p>
    2022-ci ildən etibarən Yeni Klinika və LivBonaDea Hospitalda Torakal cərrah vəzifəsində çalışmaqdadır.
    </p>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-one"></i>
    <div class="content">
    <span>Təhsili</span>
    <ul>
      <li>2002-2008-cı illərdə Ramil Kərimov Azərbaycan Dövlət Tibb Universitetində 'Müalicə işi' ixtisası üzrə təhsil alıb.</li>
      <li>2010-2011-cı illərdə ümumi cərrahiyyə ixtisası üzrə internatura keçmişdir</li>
      <li>2015-2017-cı illərdə Gülhane Askeri Tibb Akademisində(GATA) - Torakal cərrahiyyə üzrə ixtisaslaşma keçmişdir.</li>
    </ul>
    </div>
    </div>
    <div class="about-card">
    <i class="flaticon-24-hours bg-two"></i>
    <div class="content">
    <span>Üzvlüklər</span>
    <ul>
      <li>Türk Toraks Dərnəyi və TÜSAD üzvüdür
      </li>
    </ul>
    </div>
    </div>
    <div class="about-btn">
    <a href="/about/2" class="default-btn">Daha çox</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default About