import React, { useState } from 'react';
import Newsletter from '../../Components/home/Newsletter/Newsletter';

const Faq = () => {
  // State to keep track of which accordion item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Function to handle the click event for accordion items
  const handleToggle = (index) => {
    // If the clicked item is already open, close it; otherwise, open the clicked item
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div className="inner-banner inner-bg4">
        <div className="container">
          <div className="inner-title">
            <h3>Tez-tez verilən suallar</h3>
            <ul>
              <li>
                <a href="/">Əsas</a>
              </li>
              <li>FAQ</li>
            </ul>
          </div>
        </div>
        <div className="inner-banner-shape">
          <div className="shape1">
            <img src="assets/img/inner-banner/inner-banner-shape1.png" alt="Images" />
          </div>
          <div className="shape2">
            <img src="assets/img/inner-banner/inner-banner-shape2.png" alt="Images" />
          </div>
        </div>
      </div>
      <div className="faq-area faq-bg pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="faq-accordion">
                <div className="section-title">
                  <span>FAQ</span>
                  <h2>Tez-tez verilən suallar</h2>
                </div>
                <ul className="accordion">
                  {[
                    {
                      question: 'Beynəlxalq Akkreditasiya varmı?',
                      answer: 'Bəli, belə bir imkanımız var. Buraya əvvəlcədən görüş təyin etməlisiniz. Əgər Azərbaycan xaricindəsinizsə, lütfən, aşağıda göstərilən e-mail ünvanına məktub göndərin və ya burada göstərilən e-mail ünvanına hesabatlarınızı faks edin.'
                    },
                    {
                      question: 'İkinci rəy ala bilərikmi?',
                      answer: 'Bəli, belə bir imkanımız var. Buraya əvvəlcədən görüş təyin etməlisiniz. Əgər Azərbaycan xaricindəsinizsə, lütfən, aşağıda göstərilən e-mail ünvanına məktub göndərin və ya burada göstərilən e-mail ünvanına hesabatlarınızı faks edin.'
                    },
                    {
                      question: 'Sizdə müalicə əlverişlidir?',
                      answer: 'Bəli, belə bir imkanımız var. Buraya əvvəlcədən görüş təyin etməlisiniz. Əgər Azərbaycan xaricindəsinizsə, lütfən, aşağıda göstərilən e-mail ünvanına məktub göndərin və ya burada göstərilən e-mail ünvanına hesabatlarınızı faks edin.'
                    },
                    {
                      question: 'Sığorta siyasətini almaq üçün nə etməliyik?',
                      answer: 'Bəli, belə bir imkanımız var. Buraya əvvəlcədən görüş təyin etməlisiniz. Əgər Azərbaycan xaricindəsinizsə, lütfən, aşağıda göstərilən e-mail ünvanına məktub göndərin və ya burada göstərilən e-mail ünvanına hesabatlarınızı faks edin.'
                    },
                    {
                      question: 'Xəstəxana hesabını onlayn necə ödəmək olar?',
                      answer: 'Bəli, belə bir imkanımız var. Buraya əvvəlcədən görüş təyin etməlisiniz. Əgər Azərbaycan xaricindəsinizsə, lütfən, aşağıda göstərilən e-mail ünvanına məktub göndərin və ya burada göstərilən e-mail ünvanına hesabatlarınızı faks edin.'
                    }
                  ].map((item, index) => (
                    <li className="accordion-item" key={index}>
                      <a className="accordion-title" href="javascript:void(0)" onClick={() => handleToggle(index)}>
                        <i className={`bx bx-chevron-down ${openIndex === index ? 'open' : ''}`}></i>
                        {item.question}
                      </a>
                      <div className="accordion-content" style={{ display: openIndex === index ? 'block' : 'none' }}>
                        <p>{item.answer}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-counter-area">
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <div className="single-counter">
                      <h3>200+</h3>
                      <span>Müştəri</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="single-counter">
                      <h3>10+</h3>
                      <span>Xidmət</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="single-counter">
                      <h3>50+</h3>
                      <span>Köməkçi</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="single-counter">
                      <h3>25+</h3>
                      <span>İllik Təcrübə</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default Faq;
