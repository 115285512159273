import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const Hero = () => {
  return (
    <>
      <div className="banner-area banner-bg">
        <div className="container">
        <div className="row align-items-center justify-content-between flex-wrap">
  <div className="col-lg-3 col-md-6">
    <div className="banner-img-left">
      <img src="assets/img/home-one/home-one-img.png" alt="Left Image" />
    </div>
  </div>
  <div className="col-lg-5 col-md-12 text-center">
    <div className="banner-content">
      <h1>Tənəffüs həyatdır</h1>
      <p>Rezervasiya təyin edin</p>
      <div className="banner-btn">
        <a href="/appointment" className="appointment-btn">Görüş təyin edin</a>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="banner-img-right">
      <img src="assets/img/home-one/home-two-img.png" alt="Right Image" />
    </div>
  </div>
</div>

        </div>
        <div className="banner-shape">
          <div className="shape1">
            <img src="assets/img/home-one/shape1.png" alt="Images" />
          </div>
          <div className="shape2">
            <img src="assets/img/home-one/shape2.png" alt="Images" />
          </div>
        </div>
      </div>
      <div className="banner-bottom">
  <div className="container">
    <div className="row">
      {/* Sol taraftaki teknik hizmet */}
      <div className="col-lg-9">
        <div className="banner-bottom-card">
          <i className="fas fa-phone" style={{ marginRight: '8px' }}></i>
          <div className="content">
          <span>24/7 Texniki Xidmət</span> <br></br>
            <span style={{marginTop: '20px'}}>Uzman Dr.Elmar Məmmədov</span>
            <h3><a href="tel:+994502668800">+994 50 266 88 00</a></h3>
          </div>
        </div>
      </div>

      {/* Sağ taraftaki teknik hizmet */}
      <div className="col-lg-3">
        <div className="banner-bottom-card">
          <i className="fas fa-phone" style={{ marginRight: '8px' }}></i>
          <div className="content">
            <span>24/7 Texniki Xidmət</span> <br></br>
            <span style={{marginTop: '20px'}}>Uzman Dr.Ramil Kərimov</span>
            <h3><a href="tel:+994552322662">+994 55 232 26 62</a></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default Hero;

